import React from 'react';

// STYLES
import './styles.scss';

export const GOOGLE_MAPS_ROUTE = "https://www.google.com/maps/place/42+E+58th+St,+New+York,+NY+10022,+USA/@40.7624548,-73.9753171,16z";

function EventDate() {
    return (
        <div className="event-date-container">
            <div className="event-date-item-content">
                <h5>Date</h5>
                <h3>September 23rd</h3>
            </div>
            <div className="event-date-item-content borders">
                <h5>Doors Open</h5>
                <h3>2:00pm CET</h3>
            </div>
            <div className="event-date-item-content">
                <h5>Location</h5>
                <h3>Metropol, Central Milano</h3>
            </div>
        </div>
    );
}

export default EventDate;