// ANTD
import Button from 'antd/lib/button';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';

// UTILS
import { formatAddress } from '../../utils/format-address';
import HELPERS from '../../configs/helpers';

function Header() {

    const [state, dispatch] = useGlobalState();

    const connectWallet = async () => {
        HELPERS.connectWallet(dispatch);
    };

    return (
        <div className="header-container">
            <div className="header-content">
                <img src="../../assets/img/logo_dark.png" width="111px" alt="UNXD Logo" />
                <ul>
                    <li><a href="https://unxd.com/drops">Drops</a></li>
                    <li><a href="https://unxd.com/marketplace">Marketplace</a></li>
                </ul>
                {state.connected ? (
                    state.chainId === Number(process.env.REACT_APP_NETWORK_ID) ?
                        <Button type="primary" className="connected">{formatAddress(state.user)}</Button> :
                    <Button type="primary" className="connected" onClick={HELPERS.changeNetwork}>Change Network</Button>
                ) : (
                    <Button type="primary" className="connect" onClick={() => connectWallet()}>Connect Wallet</Button>
                )}
            </div>
        </div>
    );

}

export default Header;