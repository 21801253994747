import COMMON from '../configs/common';
import MetaMaskOnboarding from '@metamask/onboarding';

const onboarding = new MetaMaskOnboarding();

const isMetaMaskInstalled = () =>
  Boolean(window.ethereum && window.ethereum.isMetaMask);

const openedFromMobile = () => COMMON.MOBILE_REGEX.test(navigator.userAgent);

const onboardMetaMask = () => {
    if (openedFromMobile()) {
        window.location.href = `https://metamask.app.link/dapp/${process.env.REACT_APP_PUBLIC_URL}/`
    } else {
        metaMaskOnboarding();
    }
};
  
const metaMaskOnboarding = () => {
    onboarding.startOnboarding();
};

const changeNetwork = async () => {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${parseInt(process.env.REACT_APP_NETWORK_ID.toString(), 16)}` }],
    });
};

const connectWallet = async (dispatch) => {
    if (!isMetaMaskInstalled() || openedFromMobile()) {
        onboardMetaMask();
    } else {
        const accounts = await window.ethereum?.request({
            method: "eth_requestAccounts",
        });
        if (accounts?.length) dispatch({ user: accounts[0], connected: true });
    }
};

const HELPERS = { isMetaMaskInstalled, openedFromMobile, onboardMetaMask, changeNetwork, connectWallet };

export default HELPERS;
