import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createAlchemyWeb3, Nft } from "@alch/alchemy-web3";
import { GetNftsResponse } from "@alch/alchemy-web3";

// ANTD
import { Button } from 'antd';

// STATE
import { useGlobalState } from '../index';

// MODULES
import EventDate from '../shared/EventDate';

// STYLES
import './styles.scss';
import HELPERS from '../configs/helpers';

function Home() {

    const navigate = useNavigate();
    const [state, dispatch] = useGlobalState();
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const continueEvent = () => {
        navigate('/event');
    };

    const connectWallet = async () => {
        HELPERS.connectWallet(dispatch);
    };

    const getRevealedBoxes = async (user: string): Promise<void> => {

        setLoadingState(true);

        const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_API as string);
        const nfts = await web3.alchemy.getNfts({ owner: user, contractAddresses: [process.env.REACT_APP_DG_FAMILY_BOX as string] });

        setUserBoxTypes(nfts);

    };

    const getGlassBoxes = async (user: string): Promise<Nft[]> => {

        const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_API as string);

        const nfts = await web3.alchemy.getNfts({ owner: user, contractAddresses: [process.env.REACT_APP_CONTRACT_GLASS_BOX as string] });

        return nfts.ownedNfts;

    };

    const setUserBoxTypes = async (nfts: GetNftsResponse): Promise<void> => {

        let tokenIds: string[] = [];
        let glassBoxes = 0;
        let platinumBoxes = 0;
        let goldBoxes = 0;
        let blackBoxes = 0;

        nfts.ownedNfts.forEach((nft: Nft) => {

            const tokenId = parseInt(nft.id.tokenId);

            tokenIds.push(tokenId.toString());

            if (tokenId <= 75) platinumBoxes++;
            if (tokenId > 75 && tokenId <= 750) goldBoxes++;
            if (tokenId > 750) blackBoxes++;

        });

        let glassBoxesNfts = await getGlassBoxes(state.user) || 0;

        glassBoxesNfts.forEach((nft: Nft) => {
            glassBoxes++;
            tokenIds.push(`#${nft.id.tokenId}`);
        });

        let totalInvitations = glassBoxes;
        totalInvitations += platinumBoxes * 2;
        totalInvitations += goldBoxes * 2;
        totalInvitations += blackBoxes;

        // let totalInvitations = platinumBoxes * 2;
        // totalInvitations += goldBoxes * 2;

        dispatch({ platinumBoxes, goldBoxes, blackBoxes, glassBoxes, invitations: totalInvitations <= 10 ? totalInvitations : 10, tokenIds });

        setLoadingState(false);

    };

    useEffect(() => {
        state.user && getRevealedBoxes(state.user);
    }, [state.user]);

    return (
        <div className="home-container">
            <div className="home-content">
                <div className="home-video-container">
                    
                    <img src="https://res.cloudinary.com/dvz8qqgob/image/upload/v1685957744/UNXD/boxes_kaxrbj_zusrgd.gif" />
                </div>
                <div className="home-content-container">
                    <h1 style={{marginBottom: '0px'}}>Dolce&Gabbana</h1>
                    <h1>runway show and afterparty</h1>
                    <h2 className="event-subtitle">Milan Fashion Week womenswear</h2>
                    <EventDate />
                    <div className="home-info-content">
                        <p>Welcome back, DGFamily!</p>
                        <p>We’re excited to invite you to the official Dolce&Gabbana womenswear runway show during Milan Fashion Week on September 23rd.</p>
                        <p>Join us at the iconic Metropol venue for a spectacle on the runway followed by the exclusive and star-studded Dolce&Gabbana dinner and afterparty at Martini next door.</p>
                        <br />
                        <p><strong>This season the party doesn’t end there:</strong></p>
                        <p><strong>Please select box for ‘secret Sunday event’ if you want to join for the second day of the DG spectacle. You can expect an exclusive art and fashion presentation, followed by a private dinner and secret party.</strong></p>
                        <br />
                        <p>Spaces are very limited, with priority access given to Platinum holders, and additional spaces given away on a first come, first serve basis to Gold and multiple box holders.</p>
                        <p>Plus ones are allowed, but please note that all guests must accompany the DGFamily Box holders to gain entry.</p>
                        <p>Connect your MetaMask to verify ownership of a DGFamily Box, and join us for a day (and night) of excitement in Milano!</p>
                    </div>
                    <div className="home-actions">
                        {
                            state.connected ? (
                                state.chainId === Number(process.env.REACT_APP_NETWORK_ID) ? (
                                    <Button type="primary" size="large" onClick={() => continueEvent()} loading={loadingState} disabled={state.invitations < 1 || loadingState}>Continue</Button>
                                ) : (
                                    <Button type="primary" size="large" onClick={HELPERS.changeNetwork}>Change Network</Button>
                                )
                            ) : (
                                <Button type="primary" size="large" onClick={() => connectWallet()} loading={loadingState} disabled={loadingState}>Connect Wallet</Button>
                            )
                        }
                        {state.user && state.invitations < 1 && !loadingState ? (<p className="info-message"><strong>Notice</strong><br />This wallet does not contain any qualifying DGFamily Boxes. Please connect with a wallet holding Platinum or Gold boxes.</p>) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;