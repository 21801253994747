import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// WEB3
import Web3 from 'web3';

// MODULES
import App from './App';

// STYLES
import './index.scss';

import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    ethereum: any;
    web3: Web3;
  }
};

export interface GlobalState {
  chainId: number | undefined;
  connected: boolean;
  user: string | null;
  invitations: number;
  tokenIds: string[];
  glassBoxes: number;
  platinumBoxes: number;
  goldBoxes: number;
  blackBoxes: number;
};

const defaultGlobalState: GlobalState = {
  chainId: undefined,
  connected: false,
  user: null,
  invitations: 0,
  tokenIds: [],
  glassBoxes: 0,
  platinumBoxes: 0,
  goldBoxes: 0,
  blackBoxes: 0
};

const GlobalStateContext = React.createContext(defaultGlobalState);
const DispatchStateContext = React.createContext<any>(undefined);

type GlobalProviderProps = { children: React.ReactNode };

const GlobalStateProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    defaultGlobalState
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>
        {children}
      </DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext),
];

const registerEvents = () => {
  window.addEventListener('popstate', event => {
    window.location.href = window.location.pathname;
  }, false);
};

registerEvents();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <App />
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
