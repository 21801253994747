import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

// STATE
import { useGlobalState } from './index';

// PROVIDER
import { getProvider } from './provider';

// MODULES
import Header from './shared/Header';

// STYLES
import './App.scss';
import Home from './Home';
import Event from './Event';

function App() {

  const [state, dispatch] = useGlobalState();

  const checkNetworkId = async (): Promise<void> => {

    const provider = await getProvider();
    const chainId = await provider.eth.getChainId();

    if (chainId === parseInt(process.env.REACT_APP_NETWORK_ID as string)) {
      dispatch({ chainId });
      getAccount();
    }
  };

  const getAccount = async (): Promise<void> => {

    const provider = await getProvider();
    const accounts = await provider.eth.getAccounts();

    if (accounts.length) {
      dispatch({ user: accounts[0], connected: true });
    } else {
      dispatch({ user: null, connected: false });
    }

  };

  const listenNetworkChange = () => {
    window.ethereum?.on('chainChanged', (chainId: string) => {
      dispatch({ chainId: parseInt(chainId) });
    });
  };

  const checkProvider = async () => {
    if (window.ethereum && state.connected) {
      await getAccount();
    }
  }

  useEffect(() => {
    checkNetworkId();
  }, [state.chainId]);

  useEffect(() => {
    checkProvider();
  }, []);

  useEffect(() => {
    listenNetworkChange();
  }, []);

  return (
    <div className="app-container">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/event" element={<Event />} />
      </Routes>
    </div>
  );
}

export default App;
