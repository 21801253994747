import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

// ANTD
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// MODULES
import EventDate from '../shared/EventDate';

// STATE
import { useGlobalState } from '../index';

// STYLES
import './styles.scss';
import COMMON from '../configs/common';

function Event() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [confirmState, setConfirmState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<boolean>(false);

    // EMAIL ACCOUNT REGEX
    const isEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const checkLogin = async (): Promise<void> => {
        if (!state.connected) navigate('/');
    };

    const submitEvent = async () => {

        setLoadingState(true);

        const formData = new FormData();

        formData.append('name', form.getFieldValue('fullName'));
        formData.append('email', form.getFieldValue('email'));
        formData.append('address', form.getFieldValue('address'));
        formData.append('guestName', form.getFieldValue('guestName'));
        formData.append('discordId', form.getFieldValue('discordId'));
        formData.append('telegramId', form.getFieldValue('telegramId'));
        formData.append('whatsappId', form.getFieldValue('whatsappId'));
        formData.append('nextEvent', form.getFieldValue('nextEvent'));
        formData.append('glassbox', state.glassBoxes);
        formData.append('blackbox', state.blackBoxes);
        formData.append('goldbox', state.goldBoxes);
        formData.append('platinumbox', state.platinumBoxes);
        formData.append('timestamp', new Date(Date.now()).toString());
        formData.append('allowedInvitations', state.invitations.toString());

        let totalGuests = 0;

        const guests = form.getFieldValue('guests');
        guests.forEach((guest: string, i: number) => {
            if (guest !== '') totalGuests++;
            formData.append(`Guest${i + 1}`, `${guest}`);
        });

        formData.append('usedInvitations', totalGuests.toString());

        state.tokenIds.forEach((tokenId: string, i: number) => {
            if (i + 1 <= 21) formData.append(`box${i + 1}`, tokenId);
        });

        try {
            await axios.post(COMMON.ZAP_HOOK, formData);
            setLoadingState(false);
            setConfirmState(true);
        } catch (error: any) {
            setLoadingState(false);
            setErrorState(true);
        }

    };

    const nameChange = (e: any) => {
        const guests = form.getFieldValue('guests');
        guests[0] = e.target.value;
        form.setFieldsValue({ guests });
    }

    const onCheckAgeChange = (e: CheckboxChangeEvent) => {
        form.setFieldsValue({ checkAge: e.target.checked });
    };

    const onNextEventChange = (e: CheckboxChangeEvent) => {
        form.setFieldsValue({ nextEvent: e.target.checked });
    };

    const setFields = () => {
        const guests = Array.from(Array(state.invitations).keys()).map((key) => '');
        form.setFieldsValue({ address: state.user, guests });
    };

    useEffect(() => {
        setFields();
        //checkLogin();
    }, [state.connected]);

    return (
        <div className="event-container">
            {
                confirmState || errorState ? null : (
                    <div className="event-form-container">
                        <h1>Dolce&Gabbana Milan Fashion Week runway show and afterparty</h1>
                        <h2 className="event-subtitle">Menswear Runway and after party</h2>
                        <EventDate />
                        <div className="event-info-content">
                            <p>Your wallet contains <strong>{state.platinumBoxes} Platinum {state.platinumBoxes !== 1 ? 'Boxes' : 'Box'}</strong>, <strong>{state.goldBoxes} Gold {state.goldBoxes !== 1 ? 'Boxes' : 'Box'}</strong>, <strong>{state.blackBoxes} Black {state.blackBoxes !== 1 ? 'Boxes' : 'Box'}</strong> and <strong>{state.glassBoxes} Glass {state.glassBoxes !== 1 ? 'Boxes' : 'Box'}.</strong> You can request {/*<strong>{state.invitations} {state.invitations !== 1 ? 'invitations' : 'invitation'}</strong>*/} <strong>2 Invitations</strong> to the event including yourself.</p>
                            <p>Please fill out the details below to request invites to the event. When invitations are confirmed, you will be emailed final event details, no less than 72 hours prior to the event.</p>
                            <p>Invitation requests are limited to once per DGFamily Box. Boxes must be held in the wallet address submitted until the event.</p>
                            <p><strong>Important:</strong> You must enter names as they appear on an accepted government issued ID, license, or passport. All guests must be at least 18 years old. The venue will reserve the right to confirm your age via a valid ID at the door.</p>
                        </div>
                        <Form form={form}>
                            <div className="your-details-form-content">
                                 {/*<h3>Your Details</h3> */}
                                <div className="input-form">
                                    <Form.Item name="fullName" rules={[
                                        { required: true, message: "Full name is required!" },
                                    ]} label="Full Name" labelCol={{ span: 24 }}>
                                        <Input size="large" onChange={nameChange} placeholder="First Last"></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="email" rules={[
                                        {
                                            required: true,
                                            message: "You must submit a valid email",
                                            pattern: isEmail,
                                        },
                                    ]} label="Email" labelCol={{ span: 24 }}>
                                        <Input size="large" placeholder="name@host.com"></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="address" rules={[
                                        { required: true },
                                    ]} label="Wallet Address" labelCol={{ span: 24 }}>
                                        <Input size="large" disabled placeholder={state.user}></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="guestName" label="Guest Full name" labelCol={{ span: 24 }}>
                                        <Input size="large" placeholder="Guest Full Name"></Input>
                                    </Form.Item>
                                </div>

                                <div className="input-form">
                                    <Form.Item name="discordId" label="Discord ID (on UNXD Server)" labelCol={{ span: 24 }}>
                                        <Input size="large" placeholder="Discord Name"></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="telegramId" label="Telegram" labelCol={{ span: 24 }}>
                                        <Input size="large" placeholder="Telegram"></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="whatsappId" label="Whatsapp" labelCol={{ span: 24 }}>
                                        <Input size="large" placeholder="whatsapp"></Input>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item name="nextEvent">
                                        <Checkbox onChange={onNextEventChange}>Will you be attending the Secret Sunday event too?</Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="invitation-details-content">
                                 {/*<div className="input-form">
                                     <h3>Invitations Details</h3>
                                    <Form.List name="guests">
                                        {(fields) => (
                                            fields.map((field: FormListFieldData, i: number) => {
                                                if (i === 0) {
                                                    return (
                                                        <Form.Item {...field} key={field.key} label="Guest" labelCol={{ span: 24 }}>
                                                            <Input size="large" disabled placeholder="Full Name"></Input>
                                                        </Form.Item>
                                                    );
                                                }
                                                return (
                                                    <Form.Item {...field} key={field.key} label={`Guest ${field.key}`} labelCol={{ span: 24 }}>
                                                        <Input size="large" placeholder="Full Name"></Input>
                                                    </Form.Item>
                                                );
                                            })
                                        )}
                                    </Form.List>
                                        </div> */}
                                <div className="input-form">
                                    <Form.Item name="checkAge" rules={[
                                        { required: true },
                                    ]}>
                                        <Checkbox onChange={onCheckAgeChange}>I confirm that I am at least 18 years old, along with any guest of mine. The venue reserves the right to check the age of each guest at the door. Guests under the age of 18 will not be permitted to enter.</Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="input-form">
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button type="primary" size="large" onClick={() => submitEvent()} loading={loadingState} disabled={!form.getFieldValue('checkAge') || !form.getFieldValue('fullName') || !form.getFieldValue('email') || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}>
                                                Submit
                                            </Button>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="form-note">
                                    Please note that RSVPs are granted on a first come first served basis. Your RSVP is not guaranteed until confirmed by Dolce&Gabbana and UNXD.
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }
            {confirmState && !loadingState ? (
                <div className="event-thank-you-container">
                    <h1>Dolce&Gabbana Milan Fashion Week runway show and afterparty</h1>
                    <p>Your request to attend has been made. Confirmed invitations will receive notifications via email or Discord as soon as possible.</p>
                    <p>We hope to see you in Milan!</p>
                </div>) : null}
            {errorState && !loadingState ? (
                <div className="event-error-container">
                    <h1>Error</h1>
                    <p>There was an error while registering on the event.<br /> Please try again or contact support.</p>
                </div>
            ) : null}
        </div>
    )
}

export default Event;