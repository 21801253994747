
const MOBILE_REGEX =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

const ZAP_HOOK = 'https://hooks.zapier.com/hooks/catch/1247261/35nty3a/';

const COMMON = {
  MOBILE_REGEX,
  ZAP_HOOK
};

export default COMMON;
